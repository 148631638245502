<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <SuccessDialog :msg="successMessage" v-show="showSuccess"></SuccessDialog>
    <!-- Export Modal -->
    <b-modal
      class="p-2"
      id="export-modal"
      hide-header-close
      hide-header
      hide-footer
    >
      <b-row align-h="end">
        <button
          class="close_modal text-light border-none p-0 mx-2 mb-1"
          @click="$bvModal.hide('export-modal')"
        >
          <b-icon-x scale="1.5" />
        </button>
      </b-row>
      <p class="text-center pb-3 btn-auto">{{ $t("asset.choose_ex") }}</p>
      <b-row align-h="center">
        <!-- <button class="px-2 email-me" @click="onExport(true)">
          {{ $t("btn.send_mail") }}
        </button> -->

        <button class="px-2 download-file" @click="onExport(false)">
          {{ $t("btn.dl_file") }}
        </button>
      </b-row>
    </b-modal>
    <!-- Modal Delete -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div
          class="modal-content container-fluid pt-4 pb-2"
          style="border-radius: 10px"
        >
          <div class="row">
            <img class="del-icon mb-3" src="../../assets/delete_bin.svg" />
            <p class="mb-3">
              {{ $t("depreciation.click_todel_land") }}
            </p>
          </div>
          <div class="row flex justify-content-center text-center">
            <button type="button" class="cancel-button" data-dismiss="modal">
              {{ $t("btn.canc") }}
            </button>
            <button
              type="button"
              class="confirm-button confirm-button-red"
              data-dismiss="modal"
              @click="confirmDelete()"
            >
              {{ $t("btn.conf") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="add-modal" hide-header-close hide-header hide-footer>
      <b-row>
        <form @submit.prevent="updateassetAsRealEstate">
          <b-form-group>
            <b-row align-h="end">
              <button
                type="button"
                class="close_modal text-light border-none p-0 mx-2 mb-1"
                @click="$bvModal.hide('add-modal')"
              >
                <b-icon-x scale="1.5" />
              </button>
            </b-row>
            <h4 class="text-center pb-3" style="color: #007afe;">
              {{
                editAsset
                  ? $t("depreciation.edit_land")
                  : $t("depreciation.add_land")
              }}
            </h4>
            <div class="input-group">
              <label
                >{{ $t("asset.fixedas_num")
                }}<span class="require-red">*</span></label
              >
              <v-select
                class="search-select"
                v-model="asset"
                :options="optionAllIdFixAssets"
                label="id_fixed_asset"
                v-on:input="selectAsset"
                :disabled="editAsset"
                required
              />
            </div>
            <div class="input-group">
              <label
                >{{ $t("depreciation.deed_title")
                }}<span class="require-red">*</span></label
              >
              <input
                type="text"
                v-model="asset.deed_title"
                :disabled="disabledInput"
                required
              />
            </div>
            <div class="input-group">
              <label
                >{{ $t("depreciation.location_land")
                }}<span class="require-red">*</span></label
              >
              <input
                type="text"
                v-model="asset.location_land"
                :disabled="disabledInput"
                required
              />
            </div>
            <div class="input-group">
              <label
                >{{ $t("depreciation.deed_page")
                }}<span class="require-red">*</span></label
              >
              <input
                type="text"
                v-model="asset.deed_page"
                :disabled="disabledInput"
                required
              />
            </div>
            <div class="input-group">
              <label
                >{{ $t("depreciation.land_area")
                }}<span class="require-red">*</span></label
              >
              <input
                type="text"
                v-model="asset.land_area"
                :disabled="disabledInput"
                required
              />
            </div>
            <div class="input-group">
              <label
                >{{ $t("depreciation.purchase_date")
                }}<span class="require-red">*</span></label
              >
              <date-picker
                class="inline-block h-full"
                v-model="asset.purchase_date"
                locale="en"
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <b-row>
                    <b-col cols="3" class="flex items-center m-0 pe-0">
                      <b-button
                        variant="outline-primary"
                        class="text-primary"
                        style="
                              width: 100%;
                              border-radius: 2px;
                              height: 100%;
                              min-width: 0px;
                            "
                        type="button"
                        :disabled="disabledInput"
                        @click="togglePopover()"
                      >
                        <b-icon icon="calendar"></b-icon>
                      </b-button>
                    </b-col>
                    <b-col cols="9" class="flex items-center h-100 ps-0">
                      <input
                        :value="inputValue | formatDate"
                        class="form-control"
                        readonly
                        :disabled="disabledInput"
                        @click="togglePopover()"
                      />
                    </b-col>
                  </b-row>
                </template>
              </date-picker>
            </div>
            <div class="input-group">
              <label
                >{{ $t("depreciation.purchase_price")
                }}<span class="require-red">*</span></label
              >
              <input
                type="number"
                min="0"
                step="0.01"
                v-model="asset.purchase_price"
                :disabled="disabledInput"
                required
              />
            </div>
            <div class="input-group">
              <label
                >{{ $t("depreciation.land_status")
                }}<span class="require-red">*</span></label
              >
              <select
                v-model="asset.land_status"
                required
                :disabled="disabledInput"
                class="form-control"
              >
                <option value="mortgage">{{
                  $t("depreciation.mortgage")
                }}</option>
                <option value="burden free">{{
                  $t("depreciation.burden_free")
                }}</option>
              </select>
            </div>
            <div v-if="disabledInput">
              <div
                class="input-group"
                v-for="col in getAllAssetOtherColumn"
                :key="col.id_other_column"
              >
                <label>{{ col.name }}</label>
                <input type="text" disabled />
              </div>
            </div>
            <div v-else>
              <div
                class="input-group"
                v-for="(value, index) in asset.asset_details"
                :key="index"
              >
                <label>{{ value.name }}</label>
                <input v-model="asset.asset_details[index].value" type="text" />
              </div>
            </div>
            <b-col cols="12">
              <div class="row flex justify-content-center text-center">
                <button
                  type="button"
                  class="cancel-button"
                  data-dismiss="modal"
                  @click="$bvModal.hide('add-modal')"
                >
                  {{ $t("btn.canc") }}
                </button>
                <button
                  type="submit"
                  class="col confirm-button"
                  data-dismiss="modal"
                  :disabled="disabledInput"
                >
                  {{ $t("btn.conf") }}
                </button>
              </div>
            </b-col>
          </b-form-group>
        </form>
      </b-row>
    </b-modal>

    <div class="manage-accounting my-3" style="padding: 0.4em">
      <div class="main">
        <p class="company-name">{{ companyName }}</p>
        <div
          class="menu d-flex flex-wrap align-items-center justify-content-between my-3"
        >
          <div class="d-flex align-items-center">
            <button
              class="me-3 d-flex my-1 create-button"
              @click="openModalAdd"
            >
              <b-icon-plus font-scale="1.5" style="margin-right: 5px" />
              <p class="m-0">{{ $t("depreciation.add_data") }}</p>
            </button>
            <button
              v-if="!isNativeApp"
              class="btn-outline primary-btn me-3 d-flex my-1"
              @click="$bvModal.show('export-modal')"
            >
              <b-icon-upload font-scale="1.5" style="margin-right: 5px" />
              <p>{{ $t("depreciation.export_file") }}</p>
            </button>
          </div>
          <div class="d-flex align-items-center flex-wrap">
            <div
              class="search-box d-flex"
              style="border-radius: 10px; margin-right: 12px;"
            >
              <img src="@/assets/search-white.svg" width="20px" />
              <input
                type="text"
                v-debounce:700ms="searchByText"
                :placeholder="$t('home.search')"
                v-model="searchText"
              />
            </div>
            <button
              class="btn-icon primary-btn d-flex"
              @click="$router.push({ name: 'land-edit-log' })"
            >
              <b-icon-clock-history font-scale="1.5" />
            </button>
          </div>
        </div>
        <LandListTable
          :assetData="getAllAssets"
          :otherColumn="getAllAssetOtherColumn"
          :page="currentPage"
        />
        <div class="d-flex justify-content-center mt-3">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { authHeader } from "../../store/actions";
import Loading from "vue-loading-overlay";
import moment from "moment";
import LandListTable from "../../components/Table/Depreciation/LandListTable.vue";
import { mapGetters } from "vuex";
import baseUrl from "../../util/backend";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import SuccessDialog from "@/components/SuccessDialog.vue";
export default {
  name: "LandList",
  components: {
    LandListTable,
    Loading,
    DatePicker,
    SuccessDialog,
  },
  data() {
    return {
      isLoading: true,
      company_id: null,
      searchText: "",
      filterOptions: {},
      currentPage: 1,
      totalRows: 1,
      perPage: 100,
      asset: {
        id_asset: null,
        id_fixed_asset: null,
        asset_details: [],
      },
      showSuccess: false,
      successMessage: "",
      editAsset: false,
      del_id_asset: null,
    };
  },
  filters: {
    formatDate(value) {
      if (!value) return "";
      return moment(value).format("DD/MM/YYYY");
    },
  },
  computed: {
    ...mapGetters({
      isNativeApp: "isNativeApp",
      companyName: "companyName",
      getAllAssets: "getAllAssetsStore",
      getAllAssetOtherColumn: "getAllAssetOtherColumn",
      assets_count: "assets_count",
      optionAllIdFixAssets: "optionAllIdFixAssets",
    }),
    selectedAssetLength() {
      return this.selectedAsset.length > 0;
    },
    disabledInput() {
      return !(this.asset && this.asset.id_asset);
    },
  },
  watch: {
    currentPage() {
      this.loadLandData();
    },
  },
  methods: {
    async onExport(isMail) {
      this.isLoading = true;
      try {
        const res = await this.$store.dispatch("ExportLand", {
          company_id: this.company_id,
          filename: `${this.$t("depreciation.download_land")}_${
            this.companyName
          }_${moment().format("DD-MM-YYYY_HH-mm")}.xlsx`,
        });
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    async openModalAdd() {
      this.asset = {
        id_asset: null,
        id_fixed_asset: null,
      };
      this.editAsset = false;
      await this.$bvModal.show("add-modal");
    },
    async goToDetail(id_asset) {
      this.isLoading = true;
      this.asset = this.optionAllIdFixAssets.find(
        (asset) => asset.id_asset == id_asset
      );
      this.editAsset = true;
      await this.selectAsset();
      await this.$bvModal.show("add-modal");
      this.isLoading = false;
    },
    async searchByText() {
      this.filterOptions.text = this.searchText;
      this.currentPage = 1;
      this.loadLandData();
    },
    async loadLandData() {
      this.selectedAsset = [];
      this.isLoading = true;
      this.company_id = await this.$store.dispatch("getCompanyIdCookie");
      await this.$store.dispatch("filterAllAsset_pagination", {
        filterOptions: this.filterOptions,
        page: this.currentPage,
        company_id: this.company_id,
        asset_type: "real estate",
        limit: 100,
      });
      this.totalRows = this.assets_count;
      this.isLoading = false;
    },
    async loadAssetListOptions() {
      this.company_id = await this.$store.dispatch("getCompanyIdCookie");
      await this.$store.dispatch("getOptionAllIdFixAssets", this.company_id);
    },
    async selectAsset() {
      if (this.asset && this.asset.id_asset) {
        try {
          const res = await axios.get(
            `${baseUrl()}counting/asset/${this.asset.id_asset}`,
            authHeader()
          );
          this.asset = res.data.data.asset;
        } catch (error) {
          console.error(error);
        }
      } else {
        this.asset = {
          id_asset: null,
          id_fixed_asset: null,
        };
      }
    },
    momentDayForUpdate(date) {
      return (date = moment(date).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      }));
    },
    async updateassetAsRealEstate() {
      this.isLoading = true;
      this.asset.purchase_date = this.momentDayForUpdate(
        this.asset.purchase_date
      );

      try {
        await this.$store.dispatch("updateassetAsRealEstate", this.asset);
        this.successMessage = this.editAsset
          ? this.$t("dialog.save_edit")
          : this.$t("dialog.add_success");
        this.showSuccess = true;
        setTimeout(() => {
          this.showSuccess = false;
        }, 2000);
        this.loadLandData();
        this.loadAssetListOptions();
        this.editAsset = false;
        await this.$bvModal.hide("add-modal");
      } catch (err) {
        if (err.response) {
          switch (err.response.data.message) {
            case "deed_title was duplicated":
              alert(this.$t("depreciation.deed_title_dup_dialog"));
              break;
            case "location_land was duplicated":
              alert(this.$t("depreciation.location_land_dup_dialog"));
              break;
          }
        } else {
          alert(err.message);
        }
      }
      this.isLoading = false;
    },
    showDelete(asset) {
      this.del_id_asset = asset.id_asset;
    },
    async confirmDelete() {
      try {
        await this.$store.dispatch("deleteLand", {
          id_company: this.company_id,
          id_asset: this.del_id_asset,
        });
        this.successMessage = this.$t("dialog.delete_success");
        this.showSuccess = true;
        setTimeout(() => {
          this.showSuccess = false;
        }, 2000);
        this.loadLandData();
        this.loadAssetListOptions();
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.loadLandData();
    this.loadAssetListOptions();
  },
};
</script>

<style lang="scss" scoped>
input,
select {
  height: 45px;
}

.manage-accounting {
  text-align: left;
}

.company-name {
  font-size: 18px;
}

.create-button {
  color: #fff;
  padding: 10px 15px;
  max-width: 130px;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  width: 100%;
  max-width: 130px;
  border-radius: 10px;
  border: none;
}

.btn-outline {
  p {
    margin: 0;
  }
  min-width: fit-content;
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  &.primary-btn {
    color: #007afe;
    border-color: #007afe;
  }
  &.danger-btn {
    color: #f74949;
    border-color: #f74949;
  }
}

.btn-icon {
  color: #007afe;
  padding: 10px 10px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  border-color: #007afe;
}

.search-box {
  input {
    width: 200px;
    background-color: transparent !important;
    border-radius: 10px;
  }
  img {
    margin: 0 10px;
  }
  background-color: #e0e0e0 !important;
}

.filter {
  border-radius: 10px 10px 10px 10px !important;
  // border: 1px solid #007AFE;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);

  img {
    width: 40px;
    padding: 0.3rem;
  }
}

.cancel-button {
  text-align: center;
  display: block;
  color: #fff;
  padding: 10px 15px;
  margin: 30px 10px 10px 10px;
  width: 100%;
  max-width: 130px;
  background: #888;
  border-radius: 10px;
  border: none;
}

.confirm-button {
  text-align: center;
  display: block;
  color: #fff;
  padding: 10px 15px;
  margin: 30px 10px 10px 10px;
  width: 100%;
  max-width: 130px;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  border-radius: 10px;
  border: none;

  &-red {
    background: #ed1616;
  }
}

.search-select {
  ::v-deep .vs__search::placeholder,
  ::v-deep .vs__dropdown-toggle {
    background: #f4f4f4;
    border: none;
    height: 40px;
  }
}

.form-control {
  width: 100%;
}

.del-icon {
  height: 50px;
  margin-bottom: 15px;
}

@media only screen and (min-width: 1440px) {
  .main {
    overflow-x: scroll;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
}

/*
  Animation
*/
</style>
